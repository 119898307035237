<template>
    <OColContainer desktop>
        <OSizerPanel width="400px">
            <div class="d-flex flex-column pe-2 h-100">
                <ODataGrid
                    hideGridMenu
                    :dataObject="dsAlbums">
                    <template #cardheader>
                        <div class="p-2">
                            <div class="form-check form-switch">        
                                <input class="form-check-input" id="toggle-album-view" type="checkbox" checked @change="setAlbumView($event)">
                                <label for="toggle-album-view">{{$t("View all images across albums")}}</label>
                            </div>
                        </div>
                    </template>
                    <OColumn width="275" sortable field="Name"/>
                </ODataGrid>
            </div>
        </OSizerPanel>
        <div class="ps-2">
            <div class="mb-2 d-flex">
                <input class="form-control form-control-sm w-25" v-model="searchVal" @change="search" :placeholder='$t("Search...")'>
                <button class="btn btn-sm btn-outline-primary ms-auto" @click="addSelectedImages">{{$t("Add Selected Images")}}</button>
            </div>
            <div v-bind="$attrs" class="d-flex flex-row align-items-center flex-wrap align-content-start" @scroll.native="infiniteScroll($event)">
                <div v-for="row in dsImages.data">
                    <Image @imageSelected="imageSelected" @imageRemoved="imageRemoved" class="p-1 m-1" :row="row"/>
                </div>
            </div>
        </div>
    </OColContainer>
</template>

<script setup>
    import { ref, onMounted, defineOptions, defineEmits } from 'vue';
    import { OSizerPanel } from 'o365-ui-components';
    import Image from './Image.vue';

    defineOptions({
        inheritAttrs: false
    });

    const emit = defineEmits(['addImages']);

    const searchVal = ref('');
    const selectedImages = ref([]);

    function addSelectedImages(){
        emit('addImages', selectedImages.value);
        selectedImages.value.splice(0, selectedImages.value.length);
    }

    function imageSelected(row){
        selectedImages.value.push(row);
    }

    function imageRemoved(row){
        var index = selectedImages.value.findIndex(x => x.PrimKey === row.PrimKey);
        if (index !== -1) {
            selectedImages.value.splice(index, 1);
        }
    }

    var timeout = null;
    function infiniteScroll(event){
        var target = event.target;
        if((target.scrollHeight - target.scrollTop) - (target.clientHeight) < 200) {
            clearTimeout(timeout);
            timeout = setTimeout(function(){
                dsImages.loadNextPage();
            }, 100);
        }
    }

    function setAlbumView(event){
        if(event.target.checked){
            dsImages.recordSource.whereClause = '';
            dsImages.load();
        } else {
            var item = dsAlbums.current;
            if(item.ID == undefined){
                dsAlbums.setCurrentIndex(0);
                return;
            }
            dsImages.recordSource.whereClause = `Album_ID = ${item.ID}`;
            dsImages.load();
        }
    }

    const dsAlbums = $getOrCreateDataObject({
        id: 'dsAlbums',
        viewName: 'sviw_Image_Albums',
        maxRecords: 30,
        whereClause: "",
        loadRecents: false,
        distinctRows: false,
        fields:
        [
            {name: "ID", type: "number" },
            {name: "Name", type: "string", "sortOrder": 1, "sortDirection": "asc" },
            {name: "OrgUnit_ID", type: "number" },
            {name: "OrgUnit", type: "string"},
            {name: "OrgUnitIdPath", type: "string" },
            {name: "CreatedBy", type: "string" },
            {name: "Owner", type: "string" }
        ]
    });
    dsAlbums.enableContextFilter();

    const dsImages = $getOrCreateDataObject({
        id: 'dsImages',
        viewName: 'sviw_Image_Images2',
        maxRecords: 30,
        whereClause: "",
        loadRecents: false,
        distinctRows: false,
        dynamicLoading: true,
        fields:
        [
            {name: "PrimKey", type: "string" },
            {name: "Created", type: "datetime", "sortOrder": 1, "sortDirection": "desc" },
            {name: "FileName", type: "string" },
            {name: "FileSize", type: "number" },
            {name: "FileRef", type: "string" },
            {name: "Extension", type: "string" },
            {name: "Description", type: "string" },
            {name: "OrgUnit", type: "string" },
            {name: "OrgUnit_ID", type: "number" },
            {name: "Album_ID", type: "number" },
            {name: "Album", type: "string" },
            {name: "Keywords", type: "string" },
            {name: "ClassificationCode", type: "string" },
            {name: "Obsoleted", type: "datetime" },
            {name: "Shared", type: "boolean" },
            {name: "Longitude", type: "number" },
            {name: "Latitude", type: "number" },
        ]
    });

    dsAlbums.on("CurrentIndexChanged", () => {
        var item = dsAlbums.current;
        var checkBox = document.getElementById("toggle-album-view");
        if(!checkBox.checked){
            dsImages.recordSource.whereClause = `Album_ID = ${item.ID}`;
            dsImages.load();
        }
    });

    let searchDebounce = null;
    function search(){
        if (searchDebounce) { 
            window.clearTimeout(searchDebounce); 
        }
        searchDebounce = window.setTimeout(() => {
            if(searchVal.value == null || searchVal.value == ''){
                dsImages.recordSource.filterString = null;
            } else {
                dsImages.recordSource.filterString = `FileName LIKE '%${searchVal.value}%'`;
            }
            dsImages.load();
        }, 250);
    }

    onMounted(() => {
        dsAlbums.load();
        dsImages.load();
    })
</script>